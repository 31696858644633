<template>
  <i-container
    v-if="response"
    class="_display-flex _flex-direction-column bock--exhibit-container"
  >
    <div
      class="
        _flex-grow-1
        _display-flex
        _justify-content-center
        _align-items-center
      "
    >
      <prismic-image
        v-if="item.primary.image.url"
        :field="format"
        class="image -responsive bock--hover _margin-top-6"
        :class="style"
        @click="visible = true"
      />
    </div>
    <i-container class="_margin-top-6">
      <i-row>
        <i-column :xs="true">
          <i-button
            link
            variant="dark"
            size="sm"
            v-if="prev"
            @click="
              $router.push({
                name: 'Exhibit',
                params: {
                  uid: $route.params.uid,
                  id: slugify(prev.primary.title1),
                },
              })
            "
          >
            <i-icon icon="chevron-left" class="_margin-right-1-2"></i-icon>
            Previous
          </i-button>
        </i-column>
        <i-column :xs="true" class="_text-right">
          <i-button
            link
            variant="dark"
            size="sm"
            v-if="next"
            @click="
              $router.push({
                name: 'Exhibit',
                params: {
                  uid: $route.params.uid,
                  id: slugify(next.primary.title1),
                },
              })
            "
          >
            Next
            <i-icon icon="chevron-right" class="_margin-left-1-2"></i-icon>
          </i-button>
        </i-column>
      </i-row>
    </i-container>
    <div
      class="
        bock--exhibit-nav
        _margin-bottom-xs-4
        _margin-bottom-sm-4
        _margin-bottom-md-1
        _margin-bottom-lg-1
        _margin-bottom-xl-1
        _margin-top-1
      "
    >
      <i-nav size="sm" class="_flex-shrink-1">
        <i-nav-item
          class="_margin-left-0 _padding-left-0"
          :to="{ name: 'Exhibition', params: { uid: $route.params.uid } }"
          ><span>More from {{ activeExhibit.title }}</span></i-nav-item
        >
      </i-nav>
    </div>
    <i-modal class="zoom" v-model="visible">
      <prismic-image
        v-if="item.primary.image.url"
        :field="format"
        class="image -responsive bock--hover"
        @click="visible = false"
      />
      <i-button
        size="sm"
        link
        variant="primary"
        @click="visible = false"
        class="_padding-left-0 _margin-top-1"
        >Close</i-button
      >
    </i-modal>
    <!-- <pre v-if="response" v-html="JSON.stringify(response, null, 2)"></pre> -->
    <!-- <pre v-if="item" v-html="JSON.stringify(item, null, 2)"></pre> -->
  </i-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Exhibit",
  data() {
    return {
      response: null,
      body: [],
      item: null,
      slug: "",
      total: 0,
      index: 0,
      next: null,
      prev: null,
      visible: false,
      info: null,
      exhibit: null,
    };
  },
  computed: {
    ...mapGetters(["activeExhibit"]),
    ratio() {
      let n = Number;
      if (this.response) {
        n = Number(
          (
            this.item.primary.image.dimensions.width /
            this.item.primary.image.dimensions.height
          ).toFixed(1)
        );
      } else {
        n = 0.0;
      }
      return n;
    },
    format() {
      if (this.ratio >= 1.5) {
        return this.item.primary.image["lg-wide"];
      } else if (this.ratio <= 0.7) {
        return this.item.primary.image["lg-tall"];
      } else {
        return this.item.primary.image["lg-wide"];
      }
    },
    style() {
      if (this.ratio >= 1.5) {
        return "bock--image-wide";
      } else if (this.ratio <= 0.7) {
        return "bock--image-tall";
      } else {
        return "bock--image-square";
      }
    },
  },
  methods: {
    ...mapActions(["setExhibit"]),
    async getContent() {
      const response = await this.$prismic.client.getByUID(
        "exhibition",
        this.$route.params.uid
      );
      this.response = response;
      this.total = response.data.body.length;
      response.data.body.forEach((item, i) => {
        this.slug = this.slugify(item.primary.title1);
        let exhibit = {
          id: this.slug,
          item: item,
        };
        this.body.push(exhibit);
        if (this.slug === this.$route.params.id) {
          this.item = item;
          this.index = i;
          if (i == 0) {
            this.prev = this.response.data.body[this.total - 1];
            this.next = this.response.data.body[i + 1];
          } else if (i == this.total - 1) {
            this.prev = this.response.data.body[i - 1];
            this.next = this.response.data.body[0];
          } else {
            this.prev = this.response.data.body[i - 1];
            this.next = this.response.data.body[i + 1];
          }
        }
      });
      this.getInfo();
    },
    getInfo() {
      if (
        this.$store.state.activeExhibit.title === null ||
        this.$store.state.activeExhibit.title !== this.response.data.title
      ) {
        this.exhibit = {
          title: this.response.data.title,
          year: this.response.data.year,
          gallery: this.response.data.gallery,
          location: this.response.data.location,
        };
        this.$store.dispatch("setActiveExhibit", this.exhibit);
      }
      this.info = {
        title: this.item.primary.title1,
        medium: this.item.primary.medium,
        dimensionsIMP: this.item.primary.dimensions,
        dimensionsMET: this.item.primary.dimensions_metric,
        thumb: this.item.primary.image.square,
        info: this.item.primary.info1,
        truncate: this.truncate(this.item.primary.info1),
      };
      this.$store.dispatch("setExhibit", this.info);
    },
    deleteInfo() {
      this.info = {
        exhibition: null,
        year: null,
        gallery: null,
        location: null,
        title: null,
        medium: null,
        dimensionsIMP: null,
        dimensionsMET: null,
        thumb: null,
        info: [],
        truncate: null,
      };
      this.$store.dispatch("setExhibit", this.info);
    },
    truncate(string) {
      let values = [];
      if (string.length) {
        string.forEach((str) => {
          values = values.concat(str.text.split(" "));
        });
      }
      if (values.length > 82 || string.length > 1) {
        values = values.slice(0, 82).join(" ") + "...";
      } else {
        if (string.length) {
          values = string[0].text;
        } else {
          values = ''
        }
      }
      return values;


      // let value = "";
      // if (string.length) {
      //   value = string[0].text.split(" ");
      // }
      // if (value.length > 32 || string.length > 1) {
      //   value = value.slice(0, 32).join(" ") + "...";
      // } else {
      //   if (string.length) value = string[0].text;
      // }
      // return value;
    },
    slugify(string) {
      const a =
        "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
      const b =
        "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
      const p = new RegExp(a.split("").join("|"), "g");
      return string
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, "-and-") // Replace & with 'and'
        .replace(/[^\w-]+/g, "") // Remove all non-word characters
        .replace(/--+/g, "-") // Replace multiple - with single -
        .replace(/^-+/, "") // Trim - from start of text
        .replace(/-+$/, ""); // Trim - from end of text
    },
  },
  created() {
    this.getContent();
  },
  beforeDestroy() {
    this.deleteInfo();
  },
};
</script>
