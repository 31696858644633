var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.response)?_c('i-container',{staticClass:"_display-flex _flex-direction-column bock--exhibit-container"},[_c('div',{staticClass:"\n      _flex-grow-1\n      _display-flex\n      _justify-content-center\n      _align-items-center\n    "},[(_vm.item.primary.image.url)?_c('prismic-image',{staticClass:"image -responsive bock--hover _margin-top-6",class:_vm.style,attrs:{"field":_vm.format},on:{"click":function($event){_vm.visible = true}}}):_vm._e()],1),_c('i-container',{staticClass:"_margin-top-6"},[_c('i-row',[_c('i-column',{attrs:{"xs":true}},[(_vm.prev)?_c('i-button',{attrs:{"link":"","variant":"dark","size":"sm"},on:{"click":function($event){_vm.$router.push({
              name: 'Exhibit',
              params: {
                uid: _vm.$route.params.uid,
                id: _vm.slugify(_vm.prev.primary.title1),
              },
            })}}},[_c('i-icon',{staticClass:"_margin-right-1-2",attrs:{"icon":"chevron-left"}}),_vm._v(" Previous ")],1):_vm._e()],1),_c('i-column',{staticClass:"_text-right",attrs:{"xs":true}},[(_vm.next)?_c('i-button',{attrs:{"link":"","variant":"dark","size":"sm"},on:{"click":function($event){_vm.$router.push({
              name: 'Exhibit',
              params: {
                uid: _vm.$route.params.uid,
                id: _vm.slugify(_vm.next.primary.title1),
              },
            })}}},[_vm._v(" Next "),_c('i-icon',{staticClass:"_margin-left-1-2",attrs:{"icon":"chevron-right"}})],1):_vm._e()],1)],1)],1),_c('div',{staticClass:"\n      bock--exhibit-nav\n      _margin-bottom-xs-4\n      _margin-bottom-sm-4\n      _margin-bottom-md-1\n      _margin-bottom-lg-1\n      _margin-bottom-xl-1\n      _margin-top-1\n    "},[_c('i-nav',{staticClass:"_flex-shrink-1",attrs:{"size":"sm"}},[_c('i-nav-item',{staticClass:"_margin-left-0 _padding-left-0",attrs:{"to":{ name: 'Exhibition', params: { uid: _vm.$route.params.uid } }}},[_c('span',[_vm._v("More from "+_vm._s(_vm.activeExhibit.title))])])],1)],1),_c('i-modal',{staticClass:"zoom",model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[(_vm.item.primary.image.url)?_c('prismic-image',{staticClass:"image -responsive bock--hover",attrs:{"field":_vm.format},on:{"click":function($event){_vm.visible = false}}}):_vm._e(),_c('i-button',{staticClass:"_padding-left-0 _margin-top-1",attrs:{"size":"sm","link":"","variant":"primary"},on:{"click":function($event){_vm.visible = false}}},[_vm._v("Close")])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }